import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../components/DefaultLayout";
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  console.log(frontmatter.categories);
  return (
    <DefaultLayout>
      <div className="blog-post-container mt-12 flex flex-col lg:flex-row justify-around w-11/12 lg:w-2/3 mx-auto">
        <div className="blog-post w-11/12 lg:w-4/5">
          <h1 className="text-3xl pb-8">{frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="blog-post-sidebar w-11/12 mx-auto lg:w-4/12 px-4">
          Kategorien:
          <br />
          {frontmatter.categories ? (
            <ul>
              {frontmatter.categories.map((category) => (
                <li>{category}</li>
              ))}
            </ul>
          ) : undefined}
        </div>
      </div>
    </DefaultLayout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        categories
      }
    }
  }
`;
